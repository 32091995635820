import React, { useState } from "react";
import styled from "styled-components";
import { PageCenter } from "../../styles/Global";
import { AppLogo, IplLogo } from "../../config/icons";
import VsImg from "../../assets/images/vs_img.png";
import HeaderImg from "../../assets/images/header_bg.png";
import GameLogo from "../../assets/images/game_logo.png";
import { useQuiz } from "../../context/QuizContext";
import Button from "../ui/Button";
import { ScreenTypes } from "../../types";
import Colors from "../../styles/Colors";
import ToastModal from "../ToastModal/ToastModal";
import { BackIconDiv } from "../ui/BackButton/styled.js";
import BackArrowIcon from "../../assets/icons/back_arrow.png";

const MultipleGamesScreen = (props) => {
  const {
    quizTopic,
    selectQuizTopic,
    setCurrentScreen,
    activeGamesApiResponse,
    setSelectedGame,
    selectedGame,
    setSelectedQuiz,
  } = useQuiz();

  const [selectedQuizOption, setSelectedQuizOption] = useState(
    activeGamesApiResponse && activeGamesApiResponse.active_game?.length == 1
      ? activeGamesApiResponse.active_game[0]
      : null
  );
  const [selectedGameFromQuiz, setSelectedGameFromQuiz] = useState(null);
  const [showToast, setShowToast] = useState(null);

  const [isSelectedQuiz, setIsSelectedQuiz] = useState(
    activeGamesApiResponse && activeGamesApiResponse.active_game?.length == 1
      ? true
      : false
  );

  const GameLogoImg = styled.img`
    // width: 80%;
    // position: absolute;
    // top: 70px;
    // transform: translate(-50%, 0%);
    // left: 50%;
  `;

  const GameLogoDiv = styled.div`
    width: 80%;
    position: absolute;
    top: 70px;
    transform: translate(-50%, 0%);
    left: 50%;
    text-align: center;
  `;

  function checkDateOrTime(validDays, validHours) {
    if (!validDays || !validHours) {
      console.error("Valid days or valid hours are not provided");
      return;
    }

    const daysArray = validDays.split(",").map(Number);
    const hoursArray = validHours.split(",").map(Number);

    // Get the current day (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const currentDay = new Date().getDay();
    // console.log("currentDay - ", currentDay);

    if (!daysArray.includes(currentDay)) {
      console.log("Data not available today.");
      return false;
    }

    const currentHour = new Date().getHours();

    // console.log("currentHour - ", currentHour);
    if (!hoursArray.includes(currentHour)) {
      console.log("Promo not available at this hour.");
      return false;
    }
    return true;
  }

  return (
    <PageCenter justifyCenter>
      <img src={HeaderImg} style={{ minHeight: "100vh", width: "100%" }} />
      <GameLogoDiv>
        <GameLogoImg style={{ min: "100vh" }} src={GameLogo} />

        <div
          style={{
            marginTop: 100,
            marginBottom: 10,
            padding: 10,
            borderRadius: 10,
          }}
        >
          {!isSelectedQuiz &&
          activeGamesApiResponse &&
          activeGamesApiResponse.active_game?.length > 0
            ? activeGamesApiResponse.active_game.map((game, index) => {
                return checkDateOrTime(game?.valid_days, game?.valid_hours) ? (
                  <div
                    onClick={() => {
                      if (game && game.is_played != "1") {
                        setSelectedQuiz(game);
                        setSelectedGame(game.game_config[0]);
                        setCurrentScreen(ScreenTypes.QuestionScreen);
                      } else {
                        setShowToast({
                          modalMsg: "You have already played this game.",
                          modalheading: "Already played",
                          showtoast: true,
                          closeButton: true,
                          closeIcon: true,
                        });
                      }
                    }}
                    key={index}
                    style={{
                      marginBottom:
                        index === activeGamesApiResponse.active_game.length - 1
                          ? 10
                          : 20,
                      borderRadius: 10,
                      // background: "white",

                      boxShadow: "5px 5px 40px  #828282,-5px -5px 40px #ffffff",

                      borderRadius: "10px",
                    }}
                  >
                    {game?.game_config && game.game_config.length > 0
                      ? game.game_config.map((item, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                setSelectedQuizOption(game);
                                setSelectedGameFromQuiz(item);
                              }}
                              style={{
                                position: "relative",
                                // border: "1px solid",
                                borderRadius: 10,
                                borderColor:
                                  selectedGameFromQuiz == item
                                    ? Colors.appColor
                                    : "black",
                                // background:
                                //   selectedGameFromQuiz == item
                                //     ? Colors.appColor
                                //     : "white",
                                // padding: 10,
                                // paddingBottom:0,
                                marginBottom: 20,
                                background: !game.result_declared && game.is_played == "1"
                                  ? Colors.white
                                  : game.is_played == "1"
                                  ? Colors.gray_text
                                  : Colors.white,
                              }}
                            >
                              <div
                                style={{
                                  padding: 10,
                                  paddingRight: 20,
                                  paddingLeft: 20,
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    color:
                                      selectedGameFromQuiz == item
                                        ? "black"
                                        : "black",
                                    fontWeight: "600",
                                    textAlign: "center",
                                    fontSize: 16,
                                    minWidth: 100,
                                    marginBottom: 10,
                                  }}
                                >
                                  {item.question}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      color:
                                        selectedGameFromQuiz == item
                                          ? "black"
                                          : "black",
                                      fontWeight: "600",
                                      textAlign: "center",
                                      fontSize: 16,
                                      minWidth: 100,
                                    }}
                                  >
                                    {item.options[0]}
                                  </p>
                                  <img
                                    src={VsImg}
                                    style={{
                                      width: 60,
                                      alignSelf: "center",
                                      flex: 1,
                                    }}
                                  />
                                  <p
                                    style={{
                                      color:
                                        selectedGameFromQuiz == item
                                          ? "black"
                                          : "black",
                                      textAlign: "center",
                                      fontWeight: "600",
                                      fontSize: 16,
                                      minWidth: 100,
                                    }}
                                  >
                                    {item.options[1]}
                                  </p>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  setSelectedQuizOption(game);
                                  setSelectedGameFromQuiz(item);
                                }}
                                style={{
                                  // position: "absolute",
                                  // left: 0,
                                  // right: 0,
                                  // bottom: 0,
                                  marginTop: 10,
                                  borderRadius: 10,
                                  width: "100%",
                                  background: !game.result_declared && game.is_played == "1"
                                    ? Colors.orange
                                    : game.is_played == "1"
                                    ? "#4d4c4c"
                                    : Colors.appColor,
                                }}
                              >
                                <p
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    padding: "10px 0px",
                                  }}
                                >
                                  {!game.result_declared && game.is_played == "1"
                                    ? "Please wait for result..."
                                    : game.is_played == "1"
                                    ? "You have played this game"
                                    : "Predict Now!"}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : null}
                    {/* <div
                      style={{
                        padding: 10,
                        paddingRight: 20,
                        paddingLeft: 20,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: 16,
                          minWidth: 100,
                        }}
                      >
                        {game.title}
                      </p>
                    </div> */}
                  </div>
                ) : null;
              })
            : null}

          {isSelectedQuiz &&
          selectedQuizOption &&
          selectedQuizOption?.game_config &&
          selectedQuizOption.game_config.length > 0
            ? selectedQuizOption.game_config.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedGameFromQuiz(item);
                    }}
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      border: "1px solid",
                      borderRadius: 10,
                      borderColor:
                        selectedGameFromQuiz == item
                          ? Colors.appColor
                          : "black",
                      background:
                        selectedGameFromQuiz == item
                          ? Colors.appColor
                          : "white",
                    }}
                  >
                    <div
                      style={{
                        padding: 10,
                        paddingRight: 20,
                        paddingLeft: 20,
                        // display: "flex",
                        // flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          color:
                            selectedGameFromQuiz == item ? "white" : "black",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: 16,
                          minWidth: 100,
                          marginBottom: 20,
                        }}
                      >
                        {item.question}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            color:
                              selectedGameFromQuiz == item ? "white" : "black",
                            fontWeight: "600",
                            textAlign: "center",
                            fontSize: 16,
                            minWidth: 100,
                          }}
                        >
                          {item.options[0]}
                        </p>
                        <img
                          src={VsImg}
                          style={{ width: 60, alignSelf: "center", flex: 1 }}
                        />
                        <p
                          style={{
                            color:
                              selectedGameFromQuiz == item ? "white" : "black",
                            textAlign: "center",
                            fontWeight: "600",
                            fontSize: 16,
                            minWidth: 100,
                          }}
                        >
                          {item.options[1]}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        {/* <div style={{ height: 100, width: "100%" }}></div> */}
      </GameLogoDiv>
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <BackIconDiv
          justifyCenter
          onClick={() => setCurrentScreen(ScreenTypes.QuizTopicsScreen)}
          light={true}
        >
          <img src={BackArrowIcon} style={{ width: "20px", height: "20px" }} />
        </BackIconDiv>
      </div>
      {showToast ? (
        <ToastModal
          showtoast={showToast.showtoast}
          modalMsg={showToast?.modalMsg}
          closeButton={showToast.closeButton}
          closeIcon={showToast.closeIcon}
          modalheading={showToast.modalheading}
          onClose={() => setShowToast(null)}
        />
      ) : null}
    </PageCenter>
  );
};

export default MultipleGamesScreen;

import styled from "styled-components";

import { useQuiz } from "../../context/QuizContext";
import { PageCenter } from "../../styles/Global";
import { ScreenTypes } from "../../types";
import BackArrowIcon from "../../assets/icons/back_arrow.png";
import Copy from "../../assets/icons/copy.png";
import HeaderImg from "../../assets/images/header_bg.png";
import GameLogo from "../../assets/images/game_logo.png";
import StarIcon from "../../assets/icons/star_ic.png";
import OfferIcon from "../../assets/icons/offer_ic.png";
import WinningTeamIcon from "../../assets/icons/wining_team_ic.png";
import PredictIcon from "../../assets/icons/predict_ic.png";
import RightArrow from "../../assets/icons/right_arrow.png";
import FAQIcon from "../../assets/icons/faq_ic.png";
import { BackIconDiv } from "../ui/BackButton/styled.js";
import Card from "react-bootstrap/Card";
import { useEffect, useState } from "react";
import {
  API_CALL_TYPE,
  GET_GAMES_API,
  GET_PROFILE_API,
} from "../../services/Api.js";
import { makeApiCall } from "../../services/ApiServices.js";
import Colors from "../../styles/Colors.js";
import GameModal from "../Modal/gameModal.js";
import ToastModal from "../ToastModal/ToastModal.js";
import Loader from "../Loader/Loader.js";

const GameLogoImg = styled.img`
  // width: 80%;
  // position: absolute;
  // top: 70px;
  // transform: translate(-50%, 0%);
  // left: 50%;
`;

const GameLogoDiv = styled.div`
  width: 80%;
  position: absolute;
  top: 70px;
  transform: translate(-50%, 0%);
  left: 50%;
  text-align: center;
`;

const RewardsText = styled.p`
  // width: 80%;
  position: absolute;
  top: -20px;
  transform: translate(-50%, 0%);
  left: 50%;
  text-align: center;
  background-color: #fff;
  color: #000;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #000;
  width: 100%;
  max-width: 180px;
`;

const RewardsDiv = styled.div`
  display: flex;
  color: white;
  position: relative;
  margin-top: 85px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px;
  font-size: 14px;
  // flex-direction: row;
  // align-items: center
`;

const PlayNowDiv = styled.div`
  color: white;
  border-radius: 20px;
  border: 1px solid white;
  padding: 10px;
  width: max-content;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #6e7d2b;
  font-size: 27px;
  font-weight: 500;
  padding: 16px 20px;
  box-shadow: 0px 3px 6px #00000029;
`;

const QuizTopicsScreen = () => {
  const {
    setCurrentScreen,
    activeGamesApiResponse,
    setSelectedGame,
    setUserData,
    setActiveGamesApiResponse,
    setSelectedQuiz,
    isGamePlayed,
    setIsGamePlayed,
    userData,
  } = useQuiz();

  const [isCollectedRewardsModalOpen, setIsCollectedRewardsModalOpen] =
    useState(null);
  const [showToast, setShowToast] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isUserValid, setIsUserValid] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    if (params) {
      let userData = {
        contactMappingId: params.get("contactMappingId"),
        business_id: params.get("business_id"),
        contact_id: params.get("contact_id"),
        parent_business_id: params.get("parent_business_id"),
        token: params.get("token"),
        mobile_number: params.get("mobile_number"),
      };

      // setUserData(userData);
      getProfileApi(userData);
      // getActiveGameListApiCall(userData);
    }
  }, []);

  async function getProfileApi(userData) {
    // setShowLoader(true);
    const temp_res = {
      status: 1,
      msg: "Data Sent Successfully",
      rows: [
        {
          contactMappingId: "10640898",
          contactId: "22576",
          businessId: "45893",
          name: "Gaurav Kataria ",
          gender: "Male",
          email: "gaurav@uengage.in",
          dob: "0000-00-00",
          married: "0",
          anniversary: null,
          token: "49fa7b7f6d8c6b3fa35f854aae6d7542",
          referralCode: "4fmgnk",
        },
      ],
      rules: [],
    };

    const dd = {
      isUserValid: temp_res.status == 1 ? true : false,
      customer_name: temp_res.rows[0].name,
      ...temp_res.rows[0],
    };
    if (temp_res.status == 1) {
      setIsUserValid(true);
      setUserData(dd);
      getActiveGameListApiCall(dd);
    } else {
      setIsUserValid(false);
    }
    return;

    makeApiCall(
      API_CALL_TYPE.GET_CALL,
      GET_PROFILE_API(
        userData.parent_business_id,
        userData.contact_id,
        userData.contactMappingId,
        userData.token
      ),
      (response) => {
        setShowLoader(false);
        console.log("getProfile response=", response);
      },
      (error) => {
        console.log("getProfile error=", error);
        setShowLoader(false);
      }
    );
  }

  async function getActiveGameListApiCall(userData) {
    console.log("getActiveGameListApiCall called - ", userData);
    setShowLoader(true);
    let obj = {
      mobile_number: userData.mobile_number,
      contactMappingId: userData.contactMappingId,
      business_id: userData.business_id,
      parent_business_id: userData.parent_business_id,
    };
    makeApiCall(
      API_CALL_TYPE.POST_CALL,
      GET_GAMES_API(),
      (response) => {
        setShowLoader(false);
        setActiveGamesApiResponse(response);
        if (response.active_game && response.active_game?.length > 0) {
          let recentGame = [];
          response.active_game.map((item) => {
            if (item.is_played == "1") {
              recentGame.push(item);
            }
          });
          setIsGamePlayed(recentGame);
        }

        console.log("getActiveGameListApiCall response - ", response);
      },
      (err) => {
        console.log("getActiveGameListApiCall err - ", err);
      },
      obj,
      obj
    );
  }

  function checkDateOrTime(validDays, validHours) {
    if (!validDays || !validHours) {
      console.error("Valid days or valid hours are not provided");
      return;
    }

    const daysArray = validDays.split(",").map(Number);
    const hoursArray = validHours.split(",").map(Number);

    // Get the current day (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const currentDay = new Date().getDay();
    console.log("currentDay - ", currentDay);

    if (!daysArray.includes(currentDay)) {
      console.log("Data not available today.------");
      return false;
    }

    const currentHour = new Date().getHours();

    // console.log("currentHour - ", currentHour);
    if (!hoursArray.includes(currentHour)) {
      return false;
    }
    return true;
  }

  const goToQuestionScreen = () => {
    if (
      activeGamesApiResponse.active_game &&
      activeGamesApiResponse.active_game.length === 0
    ) {
      setShowToast({
        modalMsg: "No game available at this moment.",
        modalheading: "Please try again later.",
        showtoast: true,
        closeButton: true,
        closeIcon: true,
      });
      return;
    }

    if (
      activeGamesApiResponse.active_game &&
      activeGamesApiResponse.active_game.length === 1 &&
      activeGamesApiResponse.active_game[0].game_config?.length === 1
    ) {
      if (
        checkDateOrTime(
          activeGamesApiResponse.active_game[0].valid_days,
          activeGamesApiResponse.active_game[0].valid_hours
        ) &&
        activeGamesApiResponse.active_game[0].is_played != "1"
      ) {
        setSelectedQuiz(activeGamesApiResponse.active_game[0]);
        setSelectedGame(activeGamesApiResponse.active_game[0].game_config[0]);
        setTimeout(() => {
          setCurrentScreen(ScreenTypes.QuestionScreen);
        }, 100);
      } else {
        setShowToast({
          modalMsg: "No game available at this moment.",
          modalheading: "Please try again later.",
          showtoast: true,
          closeButton: true,
          closeIcon: true,
        });
        return;
      }
    } else {
      let valid_games = [];
      if (activeGamesApiResponse.active_game.length > 1) {
        for (let i = 0; i < activeGamesApiResponse.active_game.length; i++) {
          const element = activeGamesApiResponse.active_game[i];
          if (checkDateOrTime(element?.valid_days, element?.valid_hours)) {
            valid_games.push(element);
          }
        }
        if (valid_games.length === 0) {
          setShowToast({
            modalMsg: "No game available at this moment.",
            modalheading: "Please try again later.",
            showtoast: true,
            closeButton: true,
            closeIcon: true,
          });
          return;
        }

        if (
          valid_games.length > 0 &&
          valid_games.length === 1 &&
          valid_games[0].is_played != "1"
        ) {
          setSelectedQuiz(valid_games[0]);
          setSelectedGame(valid_games[0].game_config[0]);
          setTimeout(() => {
            setCurrentScreen(ScreenTypes.QuestionScreen);
          }, 100);
        } else {
          setCurrentScreen(ScreenTypes.MultipleGamesScreen);
        }
      }
    }
  };

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(isCollectedRewardsModalOpen?.promo_code)
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback for browsers that don't support navigator.clipboard
      const textarea = document.createElement("textarea");
      textarea.value = isCollectedRewardsModalOpen?.promo_code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      return alert("Text copied to clipboard!");
    }
  };

  return (
    <PageCenter light>
      <div
        style={{ position: "relative", width: "100%", background: "#F1F0F5" }}
      >
        <img src={HeaderImg} style={{ width: "100%" }} alt="headerImg" />
        {/* <img src={GameLogo} style={{width: "80%", position: "absolute", top: 80}}/> */}
        <GameLogoDiv>
          <GameLogoImg src={GameLogo} />
          <RewardsDiv>
            <div
              style={{
                // marginBottom:20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <img
                src={StarIcon}
                style={{ width: "40px", height: "40px", marginBottom: 10 }}
                alt="StarIcon"
              />
              <p>Discount Promos for Every Order</p>
            </div>

            <div style={{ background: "white", width: 2, height: 60 }} />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <img
                src={OfferIcon}
                style={{ width: "40px", height: "40px", marginBottom: 10 }}
                alt="OfferIcon"
              />
              <p>LP Coins Balance for extra discount</p>
            </div>
            <RewardsText>WIN AMAZING REWARDS</RewardsText>
          </RewardsDiv>

          <PlayNowDiv onClick={goToQuestionScreen}>Play Now</PlayNowDiv>
        </GameLogoDiv>
        {(isGamePlayed && isGamePlayed.length > 0) ||
        (activeGamesApiResponse &&
          activeGamesApiResponse.past_game &&
          activeGamesApiResponse.past_game.length > 0) ? (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px 10px",
              }}
            >
              <p
                style={{
                  marginTop: 50,
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: 18,
                  marginBottom: 10,
                }}
              ></p>
              <p
                style={{
                  marginTop: 50,
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                Previous Matches
              </p>
              <p
                onClick={() => {
                  setCurrentScreen(ScreenTypes.PastGamesScreen);
                }}
                style={{
                  marginTop: 50,
                  textAlign: "right",
                  fontSize: 15,
                  marginBottom: 10,
                }}
              >
                View All
              </p>
            </div>
            {(isGamePlayed && isGamePlayed.length > 0
              ? isGamePlayed.slice(0, 2)
              : (
                  activeGamesApiResponse.past_game &&
                  activeGamesApiResponse.past_game.length > 0
                ).slice(0, 1)
            ).map((item, index) => {
              return (
                <div key={index} style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      background: Colors.white,
                      padding: 10,
                      margin: "0px 10px 10px 10px",
                      borderRadius: 10,
                    }}
                  >
                    <div style={{ flex: 4 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            flex: 1,
                            textAlign: "left",
                            fontWeight: "600",
                            fontSize: 18,
                            color: Colors.black,
                          }}
                        >
                          {item?.game_config[0]?.options[0]}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            flex: 1,
                            margin: "0 10px",
                            // fontWeight: "600",
                            fontSize: 20,
                            color: Colors.black,
                          }}
                        >
                          VS
                        </p>
                        <p
                          style={{
                            flex: 1,
                            textAlign: "right",
                            fontWeight: "600",
                            fontSize: 18,
                            color: Colors.black,
                          }}
                        >
                          {item?.game_config[0]?.options[1]}
                        </p>
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: 5,
                          // fontWeight: "600",
                          fontSize: 13,
                          color: Colors.black,
                        }}
                      >
                        {item?.game_submitted_at}
                      </p>
                      {item?.user_answer ? (
                        <div
                          style={{
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              marginTop: 5,
                              // fontWeight: "600",
                              fontSize: 13,
                              color: Colors.black,
                            }}
                          >
                            You Selected
                          </p>
                          <p
                            style={{
                              marginTop: 5,
                              fontSize: 19,
                              color:
                                item?.is_winner == 1
                                  ? Colors.appColor
                                  : Colors.gray,
                            }}
                          >
                            {item?.user_answer}
                          </p>
                        </div>
                      ) : null}
                      <div
                        style={{
                          marginTop: 10,
                          background:
                            item?.is_winner == 1
                              ? Colors.appColor
                              : !item.result_declared && item.is_played == "1"
                              ? Colors.orange
                              : Colors.red,
                          padding: 5,
                          borderRadius: 8,
                        }}
                      >
                        <p
                          style={{
                            color: Colors.white,
                            textAlign: "center",
                            fontSize: 14,
                          }}
                        >
                          {!item.result_declared && item.is_played == "1"
                            ? "Please wait for result..."
                            : item?.is_winner == 1
                            ? "You Predicted Correct!"
                            : "You Predicted Wrong!"}
                        </p>
                      </div>
                    </div>
                    {item?.promo_code && item?.promo_title ? (
                      <>
                        <div
                          style={{
                            flex: 1,
                            maxWidth: 2,
                            background: Colors.lightGrey,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        />
                        <div
                          onClick={() => {
                            setIsCollectedRewardsModalOpen(item);
                          }}
                          style={{
                            flex: 1,
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 15,
                            padding: "0px 5px",
                            background: `linear-gradient(145deg, ${Colors.yellow}, ${Colors.paleYellow})`,
                            boxShadow:
                              "5px 5px 10px #5a5a5a,-5px -5px 10px #ffffff",
                          }}
                        >
                          <p style={{ fontSize: 14, color: Colors.black }}>
                            Collect Your Rewards
                          </p>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {activeGamesApiResponse && activeGamesApiResponse?.user_summary ? (
          <>
            <p
              style={{
                marginTop: 20,
                textAlign: "center",
                fontWeight: "600",
                fontSize: 18,
              }}
            >
              Your Stats
            </p>
            <Card
              body
              style={{
                background: "white",
                marginTop: 10,
                marginLeft: 10,
                marginRight: 10,
                borderRadius: 10,
                padding: 10,
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <p>Game Played</p>
                  <p
                    style={{
                      fontSize: 30,
                      color: "blue",
                      fontWeight: "600",
                      marginTop: 5,
                    }}
                  >
                    {activeGamesApiResponse?.user_summary[0]?.total_games
                      ? activeGamesApiResponse?.user_summary[0]?.total_games
                      : "0"}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <p>Game Won</p>
                  <p
                    style={{
                      fontSize: 30,
                      color: "green",
                      fontWeight: "600",
                      marginTop: 5,
                    }}
                  >
                    {activeGamesApiResponse?.user_summary[0]?.won
                      ? activeGamesApiResponse?.user_summary[0]?.won
                      : "0"}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <p>Offers Received</p>
                  <p
                    style={{
                      fontSize: 30,
                      color: "orange",
                      fontWeight: "600",
                      marginTop: 5,
                    }}
                  >
                    {activeGamesApiResponse?.user_summary[0]?.offer_collected
                      ? activeGamesApiResponse?.user_summary[0]?.offer_collected
                      : "0"}
                  </p>
                </div>
              </div>
            </Card>
          </>
        ) : null}
        <p
          style={{
            marginTop: 40,
            textAlign: "center",
            fontWeight: "600",
            fontSize: 18,
          }}
        >
          Game Rules
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Card
            body
            style={{
              background: "white",
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 10,
              padding: 10,
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
              }}
            >
              <img
                src={WinningTeamIcon}
                style={{ width: "40px", height: "40px", marginBottom: 10 }}
              />
              <p style={{ fontSize: 15, textAlign: "center" }}>
                Guess the winning team before the match starts
              </p>
            </div>
          </Card>

          <Card
            body
            style={{
              background: "white",
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 10,
              padding: 10,
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
              }}
            >
              <img
                src={PredictIcon}
                style={{ width: "40px", height: "40px", marginBottom: 10 }}
              />
              <p style={{ fontSize: 15, textAlign: "center" }}>
                If the predicted team wins, you win the game
              </p>
            </div>
          </Card>
        </div>
        <div style={{ height: 100 }} />
      </div>
      <BackIconDiv justifyCenter>
        <img src={BackArrowIcon} style={{ width: "20px", height: "20px" }} />
      </BackIconDiv>

      {isCollectedRewardsModalOpen ? (
        <GameModal
          isOpen={isCollectedRewardsModalOpen}
          showtoast={false}
          onClose={() => setIsCollectedRewardsModalOpen(null)}
        >
          <div
            style={{
              padding: "20px 10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 28, textAlign: "center" }}>😊</p>
            <p style={{ fontSize: 18, textAlign: "center", marginTop: 10 }}>
              You've Won
            </p>
            <p
              style={{
                fontSize: 25,
                fontWeight: "600",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              {isCollectedRewardsModalOpen?.promo_title}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 15,
                borderRadius: 10,
                background: Colors.black,
                marginTop: 10,
              }}
            >
              <p
                style={{
                  fontSize: 23,
                  textAlign: "center",
                  marginRight: 10,

                  color: Colors.white,
                }}
                onClick={handleCopy}
              >
                {isCollectedRewardsModalOpen?.promo_code}
              </p>
              <img
                src={Copy}
                style={{ width: "20px", height: "18px", color: Colors.white }}
              />
            </div>
          </div>
        </GameModal>
      ) : null}
      <Loader showLoader={showLoader} />
      {!isUserValid || !userData?.isUserValid ? (
        <ToastModal
          showtoast={true}
          modalMsg={"You are not authorized to play this game."}
          modalheading={"Not Authorized"}
        />
      ) : null}
      {showToast ? (
        <ToastModal
          showtoast={showToast.showtoast}
          modalMsg={showToast?.modalMsg}
          closeButton={showToast.closeButton}
          closeIcon={showToast.closeIcon}
          modalheading={showToast.modalheading}
          onClose={() => setShowToast(null)}
        />
      ) : null}
    </PageCenter>
  );
};

export default QuizTopicsScreen;

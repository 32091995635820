import React, { useState } from "react";
import styled from "styled-components";
import { PageCenter } from "../../styles/Global";
import HeaderImg from "../../assets/images/header_bg.png";
import GameLogo from "../../assets/images/game_logo.png";
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import Colors from "../../styles/Colors";
import { BackIconDiv } from "../ui/BackButton/styled.js";
import BackArrowIcon from "../../assets/icons/back_arrow.png";
import GameModal from "../Modal/gameModal.js";
import Copy from "../../assets/icons/copy.png";

const PastGamesScreen = () => {
  const { setCurrentScreen, activeGamesApiResponse, isGamePlayed } = useQuiz();
  const GameLogoImg = styled.img`
    // width: 80%;
    // position: absolute;
    // top: 70px;
    // transform: translate(-50%, 0%);
    // left: 50%;
  `;

  const GameLogoDiv = styled.div`
    width: 90%;
    position: absolute;
    top: 70px;
    transform: translate(-50%, 0%);
    left: 50%;
    text-align: center;
  `;
  const [pastGamesRes] = useState([1, 2, 3, 4, 5, 6, 7]);

  const [isCollectedRewardsModalOpen, setIsCollectedRewardsModalOpen] =
    useState(null);

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(isCollectedRewardsModalOpen?.promo_code)
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback for browsers that don't support navigator.clipboard
      const textarea = document.createElement("textarea");
      textarea.value = isCollectedRewardsModalOpen?.promo_code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      return alert("Text copied to clipboard!");
    }
  };
  return (
    <PageCenter justifyCenter>
      <img src={HeaderImg} style={{ minHeight: "100vh", width: "100%" }} />

      <BackIconDiv
        justifyCenter
        onClick={() => setCurrentScreen(ScreenTypes.QuizTopicsScreen)}
        light={true}
      >
        <img src={BackArrowIcon} style={{ width: "20px", height: "20px" }} />
      </BackIconDiv>
      <GameLogoDiv>
        <GameLogoImg src={GameLogo} />

        <div style={{ marginTop: 50 }}>
          {(isGamePlayed && isGamePlayed.length > 0) ||
          (activeGamesApiResponse &&
            activeGamesApiResponse.past_game.length > 0)
            ? (isGamePlayed && isGamePlayed.length > 0
                ? isGamePlayed
                : (
                    activeGamesApiResponse.past_game &&
                    activeGamesApiResponse.past_game.length > 0
                  ).slice(0, 1)
              ).map((item, index) => {
                return (
                  <div key={index} style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        background: Colors.white,
                        padding: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    >
                      <div style={{ flex: 4 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "start",
                              flex: 1,
                              fontWeight: "600",
                              fontSize: 18,
                              color: Colors.black,
                            }}
                          >
                            {item.game_config[0].options[0]}
                          </p>
                          <p
                            style={{
                              flex: 1,
                              margin: "0 10px",
                              // fontWeight: "600",
                              fontSize: 20,
                              color: Colors.black,
                            }}
                          >
                            VS
                          </p>
                          <p
                            style={{
                              flex: 1,
                              textAlign: "right",
                              fontWeight: "600",
                              fontSize: 18,
                              color: Colors.black,
                            }}
                          >
                            {item.game_config[0].options[1]}
                          </p>
                        </div>
                        <p
                          style={{
                            marginTop: 5,
                            // fontWeight: "600",
                            fontSize: 13,
                            color: Colors.black,
                          }}
                        >
                          {item.game_submitted_at}
                        </p>
                        {item?.user_answer ? (
                          <div>
                            <p
                              style={{
                                marginTop: 5,
                                // fontWeight: "600",
                                fontSize: 13,
                                color: Colors.black,
                              }}
                            >
                              You Selected
                            </p>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 19,
                                color:
                                  item.is_winner == 1
                                    ? Colors.appColor
                                    : Colors.gray,
                              }}
                            >
                              {item.user_answer}
                            </p>
                          </div>
                        ) : null}
                        <div
                          style={{
                            marginTop: 10,
                            background:
                              !item.result_declared && item.is_played == "1"
                                ? Colors.orange
                                : item.is_winner == 1
                                ? Colors.appColor
                                : Colors.gray,
                            padding: 5,
                            borderRadius: 8,
                          }}
                        >
                          <p
                            style={{
                              color: Colors.white,
                              textAlign: "center",
                              fontSize: 14,
                            }}
                          >
                            {!item.result_declared && item.is_played == "1"
                              ? "Please Wait for result...."
                              : item.is_winner == 1
                              ? "You Predicted Correct!"
                              : "You Predicted Wrong!"}
                          </p>
                        </div>
                      </div>
                      {item?.promo_code && item?.promo_title ? (
                        <>
                          <div
                            style={{
                              flex: 1,
                              maxWidth: 2,
                              background: Colors.lightGrey,
                              marginLeft: 10,
                              marginRight: 10,
                            }}
                          />
                          <div
                            onClick={() => {
                              setIsCollectedRewardsModalOpen(item);
                            }}
                            style={{
                              flex: 1,
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 15,
                              padding: "0px 5px",
                              background: `linear-gradient(145deg, ${Colors.yellow}, ${Colors.paleYellow})`,
                              boxShadow:
                                "5px 5px 10px #5a5a5a,-5px -5px 10px #ffffff",
                            }}
                          >
                            <p style={{ fontSize: 14, color: Colors.black }}>
                              Collect Your Rewards
                            </p>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              })
            : null}
          {pastGamesRes.length > 6 ? (
            <div style={{ height: "70px" }}></div>
          ) : null}
        </div>
      </GameLogoDiv>
      {isCollectedRewardsModalOpen ? (
        <GameModal
          isOpen={isCollectedRewardsModalOpen}
          showtoast={false}
          onClose={() => setIsCollectedRewardsModalOpen(null)}
        >
          <div
            style={{
              padding: "20px 10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 28, textAlign: "center" }}>😊</p>
            <p style={{ fontSize: 18, textAlign: "center", marginTop: 10 }}>
              You've Won
            </p>
            <p
              style={{
                fontSize: 25,
                fontWeight: "600",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              {isCollectedRewardsModalOpen?.promo_title}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 15,
                borderRadius: 10,
                background: Colors.black,
                marginTop: 10,
              }}
            >
              <p
                style={{
                  fontSize: 23,
                  textAlign: "center",
                  marginRight: 10,

                  color: Colors.white,
                }}
                onClick={handleCopy}
              >
                {isCollectedRewardsModalOpen?.promo_code}
              </p>
              <img
                src={Copy}
                style={{ width: "20px", height: "18px", color: Colors.white }}
              />
            </div>
          </div>
        </GameModal>
      ) : null}
      {/* <div
        onClick={() => {
          setCurrentScreen(ScreenTypes.QuizTopicsScreen);
        }}
        style={{
          position: "fixed",
          left: 10,
          right: 10,
          bottom: 20,
          background: Colors.appColor,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 10,
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "white",
            fontFamily: "sans-serif",
          }}
        >
          Go Back
        </p>
      </div> */}
    </PageCenter>
  );
};

export default PastGamesScreen;

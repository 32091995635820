import axios from "axios";
import { API_CALL_TYPE } from "./Api";

const getGenericHeaders = async (passToken) => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    token: passToken,
  };
};

const makeGetApiCall = async (
  url,
  callback,
  errorCallBack,
  passToken = false,
  headers
) => {
  let tempHeaders = await getGenericHeaders(passToken);
  if (headers) {
    tempHeaders = { ...tempHeaders, ...headers };
  }
  console.log("makeGetApiCall url - ", url);

  axios
    .get(url, {
      headers: tempHeaders,
    })
    .then((result) => {
      console.log("res - ", result);
      if (callback && typeof callback === "function") {
        callback(result.data);
      }
    })
    .catch((error) => {
      if (errorCallBack && typeof errorCallBack === "function") {
        errorCallBack(error);
      } else {
        console.error("Error in makeGetApiCall  error callback:", error);
      }
    });
};

const makePostApiCall = async (
  url,
  callback,
  errorCallBack,
  body,
  formData,
  isUrlEncoded = false,
  headers,
  isMultiPart = false
) => {
  let tempHeaders = await getGenericHeaders();
  tempHeaders["Content-Type"] = isMultiPart
    ? "application/json"
    : isUrlEncoded
    ? "application/x-www-form-urlencoded"
    : "application/json";
  if (headers) {
    tempHeaders = { ...tempHeaders, ...headers };
  }
  console.log("makePostApiCall formData - ", formData);

  axios
    .post(url, formData, {
      headers: tempHeaders,
    })
    .then((result) => {
      if (callback && typeof callback === "function") {
        callback(result.data);
      }
    })
    .catch((error) => {
      if (errorCallBack && typeof errorCallBack === "function") {
        errorCallBack(error);
      } else {
        console.error("Error in makeGetApiCall  error callback:", error);
      }
    });
};

export const makeApiCall = (
  apiCallType,
  urlPath,
  callback,
  errorCallBack,
  formData,
  body = {},
  headers,
  isUrlEncoded = false,
  isMultiPart = false
) => {
  console.log("makeApiCall headers - ", headers);
  if (apiCallType === API_CALL_TYPE.GET_CALL) {
    makeGetApiCall(urlPath, callback, errorCallBack, true, headers);
  } else if (apiCallType === API_CALL_TYPE.POST_CALL) {
    makePostApiCall(
      urlPath,
      callback,
      errorCallBack,
      body,
      formData,
      isUrlEncoded,
      headers,
      isMultiPart
    );
  }
};

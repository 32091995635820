import { Config } from "../utils/AppConstant";

export const API_CALL_TYPE = {
    GET_CALL: "GET",
    POST_CALL: "POST",
    PUT_CALL: "PUT",
  };

  export const GET_GAMES_API = () => {
    return Config.CRM_API_URL + `get/active/game/list`;
  };
  export const SAVE_GAME_API = () => {
    return Config.CRM_API_URL + `save/game/entry`;
  };
  export const GET_PROFILE_API = (parentId, contactId, contactMappingId, token) => {
    return (
      Config.API_URL +
      `client/getProfile?businessId=${parentId}&contactId=${contactId}&contactMappingId=${contactMappingId}&token=${token}`
    );
  };